
import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";
import { useLoaderStore } from "@/store/LoaderStore";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTScrollTop,
    KTLoader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const loaderStore = useLoaderStore();

    // if (!store.getters.isUsuarioLogado) {
    //   //router.push({ name: "login" });
    //   return;
    // }

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    // store.dispatch(Actions.SET_CONCESSIONARIA_LIST);
    // initialize html element classes
    HtmlClass.init();
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      //check if current user is authenticated
      // if (!store.getters.isUsuarioLogado) {
      //   // console.log('deslogado 116');
      //   router.push({ name: "login" });
      //   return;
      // }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading"); /* By removing the "page-loading" class, the side menu assumes behavior with transition when activated */
      }, 500);
    });

    watch(() => loaderStore.status, () => {
      if(loaderStore.status) {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading")
      } else {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading") /* By removing the "page-loading" class, the side menu assumes behavior with transition when activated */
      }
    })

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();
        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      loaderStore
    };
  },
});
