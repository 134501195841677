
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";
import { useAuthStore } from "@/store/AuthStore";
import { useLoaderStore } from "@/store/LoaderStore";
import useAlert from "@/composables/Alert";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const authStore = useAuthStore();
    const loaderStore = useLoaderStore();
    const { showTimeAlert } = useAlert()

    const signOut = async () => {
      loaderStore.open()
      try {
        const result = await authStore.logoutUser()
        if(result.status === 204) {
          return router.push("/")
        } else {
          showTimeAlert("Falha ao tentar fazer logout na aplicação", "error")
        }
      } catch (error) {
        showTimeAlert("Falha ao tentar fazer logout na aplicação", "error")
      }
      return loaderStore.close()
    };


    return {
      signOut,
    };
  },
});
