
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import AuthService from "@/services/AuthService";
import { useStore } from "vuex";
import { useAuthStore } from "@/store/AuthStore";
import { useLoaderStore } from "@/store/LoaderStore";
import useAlert from "@/composables/Alert";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const scrollElRef = ref<null | HTMLElement>(null);
    const roleLevel = ref();
    const authStore = useAuthStore();
    const loaderStore = useLoaderStore();
    const { showTimeAlert } = useAlert();

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    function getUserLevel() {
      roleLevel.value = authStore.user.user.role.level
    }

    onBeforeMount(() => getUserLevel())

    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    store.watch(
      () => "store.getters.concessionariaSelectedInfo.modeloAgenda",
      () => {
        const refLinkAgendamentos = document.getElementById("agenda-virtual-sublink-quadro-agendamento");
        const refLinkMultiview = document.getElementById("agenda-virtual-sublink-multiview");

        if (!refLinkAgendamentos || !refLinkMultiview) {
          return;
        }

        if (store.getters.concessionariaSelectedInfo.modeloAgenda == "CampV1") {
          refLinkMultiview.classList.add("d-none");
          refLinkAgendamentos.classList.remove("d-none");
        } else if (store.getters.concessionariaSelectedInfo.modeloAgenda == "HyundaiV1") {
          refLinkAgendamentos.classList.add("d-none");
          refLinkMultiview.classList.remove("d-none");
        } else {
          refLinkAgendamentos.classList.remove("d-none");
          refLinkMultiview.classList.remove("d-none");
        }
      }
    );

    async function signOut() {
      loaderStore.open()
      try {
        const result = await authStore.logoutUser()
        if(result.status === 204) {
          localStorage.clear()
          return router.push("/")
        } else {
          showTimeAlert("Falha ao tentar fazer logout na aplicação", "error")
        }
      } catch (error) {
        showTimeAlert("Falha ao tentar fazer logout na aplicação", "error")
      }
      return loaderStore.close()
    }

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      translate,
      signOut,
      route,
      roleLevel
    };
  },
});
